.embedPortraitStyle {
  width: 540px;
  height: 675px;
  border-radius: 6px;
  /* box-shadow: inset 0 0 0 2px #000, inset 0 0 0 4px white; */
}

.miniGridPreviewEmbed {
  border-radius: 6px;
  overflow: hidden;
  height: auto;
  width: auto;
}

.embedControlsFontFix {
  font-family: 'CeraPro', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.portraitEmbedBgImage {
  margin: 4px;
  border-radius: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
}

.bookCoverEffect {
  box-shadow: inset 4px 0 10px rgba(0, 0, 0, 0.1);
}

.bookCoverEffect:after {
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  bottom: 0;
  width: 3px;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 1px 0 3px rgba(255, 255, 255, 0.1);
}
