/* override */
.userTopNavAvatar div:first-child {
  background-color: #ddd;
  border-radius: 50%;
}

.opInput {
  box-shadow: 0 0 0 3px rgb(230 230 230 / 54%),
    inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  background-color: #fafbfc80 !important;
  border: 1px solid #9fa6b2 !important;
  font-weight: 500;
  font-size: 1.125rem;
}

.opInput:focus {
  box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45),
    inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  background-color: #fff !important;
  border: 1px solid #1c64f2 !important;
}

.appTopNav {
  background-color: #242327;
}
