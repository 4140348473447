.sectionTableHeader {
}

.sectionTableRowItem {
  background-color: var(--canvas-inner-shadow);
  box-shadow: var(--canvas-block-table-shadow);
  /* font-family: PensumDEMO; */
  /* padding-top: 0.5rem; */
  /* box-shadow: inset -1px 0 0 rgb(104, 104, 104), inset 0 -1px 0 rgb(104, 104, 104); */
}

.sectionTopLevelBGColor .sectionTableRowItem {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: var(--canvas-block-table-shadow-custom-bg);
}

.sectionTableColItem {
  box-shadow: var(--canvas-table-cell-shadow);
  /* box-shadow: inset -1px 0 0 rgb(104, 104, 104), inset 0 -1px 0 rgb(104, 104, 104); */
}

.sectionTableColItem textarea {
  /* background-color: #DDD; */
  background-color: transparent;
  padding: 0.75rem 1rem;
  width: 100%;
  margin-bottom: -5px;
  outline: none;
  resize: none;
}

.sectionTableColItem:focus-within {
  /* background-color: rgba(221, 221, 221, 0.3); */
  background-color: var(--canvas-inner-shadow);
}
