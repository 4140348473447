/*

*/

.loadingGridContent {
  border-left: var(--canvas-thickness) solid var(--canvas-border);
  border-top: var(--canvas-thickness) solid var(--canvas-border);
  box-shadow: var(--canvas-block-shadow);
  border-radius: 6px;
}
.loadingGridContentInner {
  width: 200px;
  height: 200px;
  /* border: var(--canvas-thickness) solid var(--canvas-border); */
}

.publicWrapper {
  background-color: var(--overall-bg-color);
  padding-bottom: 50px;
}

.boxes {
  /* display: grid; */
  /* background-color: #fff; */
  min-height: 800px;
  /* border-radius: 0.25rem; */
  border-radius: 10px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  min-height: calc(100vh - 205px);
  /* --selected-shadow: #3398db3c; */
  /* margin-bottom: 50px; */
}

.box:hover .editSectionIcon {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.box:hover .editSectionIcon:hover {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.grid-container-skeleton {
  grid-template-rows: auto !important;
}

.skeletonGrid {
  background-color: #fff;
  border-right: 1px dashed rgba(0, 0, 0, 0.2);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  min-height: 75px;
}

.skeletonGrid.ui-selecting {
  background-color: rgb(159, 206, 245) !important;
  border-color: rgb(9, 90, 155) !important;
  position: relative;
  z-index: 5;
}

.skeletonGrid.ui-selected {
  /* background: rgba(50,154,240,0.5); */
  /* border-color: #329af0 !important; */
  background-color: rgb(101, 173, 232) !important;
  border-color: rgb(9, 90, 155) !important;
  position: relative;
  z-index: 5;
}

.gridTopEdge {
  border-top: var(--canvas-thickness) solid var(--canvas-border);
  box-shadow: inset 0 4px 0 var(--canvas-inner-shadow);
}

.gridTopEdge.ui-selected,
.gridTopEdge.ui-selecting {
  box-shadow: inset 0 4px 0 var(--selected-shadow);
}

.gridLeftEdge {
  border-left: var(--canvas-thickness) solid var(--canvas-border);
  box-shadow: inset 4px 0 0 var(--canvas-inner-shadow);
}
.gridLeftEdge.ui-selected,
.gridLeftEdge.ui-selecting {
  box-shadow: inset 4px 0 0 var(--selected-shadow);
}

.gridRightEdge {
  border-right: var(--canvas-thickness) solid var(--canvas-border);
  box-shadow: inset -4px 0 0 var(--canvas-inner-shadow);
}
.gridRightEdge.ui-selected,
.gridRightEdge.ui-selecting {
  box-shadow: inset -4px 0 0 var(--selected-shadow);
}

.gridBottomEdge {
  border-bottom: var(--canvas-thickness) solid var(--canvas-border);
  box-shadow: inset 0 -4px 0 var(--canvas-inner-shadow);
}
.gridBottomEdge.ui-selected,
.gridBottomEdge.ui-selecting {
  box-shadow: inset 0 -4px 0 var(--selected-shadow);
}

.gridTopLeftCorner {
  box-shadow: inset 0 4px 0 var(--canvas-inner-shadow),
    inset 4px 0 0 var(--canvas-inner-shadow);
  border-top-left-radius: 6px;
}
.gridTopLeftCorner.ui-selected,
.gridTopLeftCorner.ui-selecting {
  box-shadow: inset 0 4px 0 var(--selected-shadow),
    inset 4px 0 0 var(--selected-shadow);
}
.gridTopRightCorner {
  box-shadow: inset 0 4px 0 var(--canvas-inner-shadow),
    inset -4px 0 0 var(--canvas-inner-shadow);
  border-top-right-radius: 6px;
}
.gridTopRightCorner.ui-selected,
.gridTopRightCorner.ui-selecting {
  box-shadow: inset 0 4px 0 var(--selected-shadow),
    inset -4px 0 0 var(--selected-shadow);
}
.gridBottomLeftCorner {
  box-shadow: inset 4px 0 0 var(--canvas-inner-shadow),
    inset 0 -4px 0 var(--canvas-inner-shadow);
  border-bottom-left-radius: 6px;
}
.gridBottomLeftCorner.ui-selected,
.gridBottomLeftCorner.ui-selecting {
  box-shadow: inset 4px 0 0 var(--selected-shadow),
    inset 0 -4px 0 var(--selected-shadow);
}
.gridBottomRightCorner {
  box-shadow: inset -4px 0 0 var(--canvas-inner-shadow),
    inset 0 -4px 0 var(--canvas-inner-shadow);
  border-bottom-right-radius: 6px;
}
.gridBottomRightCorner.ui-selected,
.gridBottomRightCorner.ui-selecting {
  box-shadow: inset -4px 0 0 var(--selected-shadow),
    inset 0 -4px 0 var(--selected-shadow);
}

.resizeHorz {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 16px;
  transition: opacity 0.3s ease-in;
  /* transition-delay: .2s; */
  opacity: 0;
  user-select: none;
}

.resizeHorzLine {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  box-shadow: 2px 0 0 #006aff50, -2px 0 0#006aff50;
  background-color: #006aff;
}

.resizeHorz:hover {
  opacity: 1;
}

.resizeHorzResizing,
.resizeVertResizing {
  opacity: 1 !important;
}

.resizeHorzItems {
  position: absolute;
  top: calc(50% - 13px);
  right: -13px;
}

.resizeHorzItem {
  background-color: #006aff;
  color: #fff;
  border-radius: 3px;
  padding: 5px;
  cursor: ew-resize;
  user-select: none;
}

.resizeVert {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 16px;
  transition: opacity 0.3s ease-in;
  opacity: 0;
}

.resizeVertLine {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 2px;
  box-shadow: 0 2px 0 #006aff50, 0 -2px 0 #006aff50;
  background-color: #006aff;
}

.resizeVertItems {
  position: absolute;
  left: calc(50% - 10px);
  bottom: -10px;
}

.resizeVert:hover {
  opacity: 1;
}

.resizeVertItem {
  background-color: #006aff;
  color: #fff;
  border-radius: 3px;
  padding: 5px;
  cursor: ns-resize;
  user-select: none;
}

.addVertRows {
  position: absolute;
  left: calc(50% - 40px);
  bottom: -10px;
}

.addVertRow:hover {
  opacity: 1;
}

.addVertRow {
  background-color: #333;
  color: #fff;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  user-select: none;
}

.cantMove .resizeVertLine {
  box-shadow: 0 2px 0 #dc262650, 0 -2px 0 #dc262650;
  background-color: #dc2626;
}

.cantMove .resizeVertItem {
  background-color: #dc2626;
}

.cantMove .resizeHorzLine {
  box-shadow: 0 2px 0 #dc262650, 0 -2px 0 #dc262650;
  background-color: #dc2626;
}

.cantMove .resizeHorzItem {
  background-color: #dc2626;
}

.box {
  position: relative;
  min-width: 0;
  min-height: 250px;
  background: #fff;
  box-shadow: var(--canvas-block-shadow);
  /* opacity: 0.3; */
  /* box-shadow: inset -2px 0 0 var(--canvas-border), inset 0 -2px 0 var(--canvas-border), inset -1px 0 0 5px var(--canvas-inner-shadow); */
}

.box.hasCustomBGColor {
  box-shadow: var(--canvas-block-shadow-custom-bg);
}

.hasLeftBorder {
  border-left: var(--canvas-thickness) solid var(--canvas-border);
}

.hasTopBorder {
  border-top: var(--canvas-thickness) solid var(--canvas-border);
}
.hasTopRightRadius {
  border-top-right-radius: 6px;
}

.hasTopRightRadius .sectionImage {
  border-top-right-radius: 6px;
}

.hasTopLeftRadius {
  border-top-left-radius: 6px;
}

.hasTopLeftRadius .sectionImage {
  border-top-left-radius: 6px;
}

.hasBottomRightRadius {
  border-bottom-right-radius: 6px;
}

.hasBottomRightRadius .sectionImage {
  border-bottom-left-radius: 6px;
}

.hasBottomLeftRadius {
  border-bottom-left-radius: 6px;
}

.hasBottomLeftRadius .sectionImage {
  border-bottom-left-radius: 6px;
}

.iconHeaderSquare {
  border-radius: 3px;
}
.iconcircle {
  border-radius: 50%;
}

.icononly {
  border-radius: 0px;
  background: transparent !important;
  padding: 0px !important;
  padding-right: 5px !important;
}

.icononly.order-last {
  padding: 0px !important;
  padding-left: 5px !important;
  margin-right: 0px !important;
}

.iconcircle.order-last,
.iconHeaderSquare.order-last {
  margin-right: 0px !important;
}

.boxTitleBlock {
  padding: 30px 40px;
}

.boxTitleBlockEmpty {
  padding: 0px !important;
  padding-top: 30px !important;
}

.boxTitleBlock:before {
  content: '';
  position: absolute;
  top: 22px;
  left: 20px;
  height: calc(100% - 45px);
  width: 3px;
  border-radius: 2px;
  background-color: var(--title-border-color);
}

.noLeftBorder.boxTitleBlock:before {
  content: none;
}

.sectionWrapperSection .boxTitleBlockEmpty {
  padding: 0px !important;
  padding-top: 15px !important;
}

.sectionWrapperSection .boxTitleBlock:before {
  /* content: ''; */
  position: absolute;
  top: 20px;
  left: 15px;
  height: calc(100% - 25px);
  width: 3px;
  border-radius: 2px;
  background-color: var(--title-border-color);
}

.titleText {
  font-size: 32px;
  color: var(--canvas-text-color);
  font-family: 'Pensum';
}

.boldSectionTitle .titleText {
  font-family: 'CeraPro-Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.promptText {
  margin-top: 5px;
  opacity: var(--block-title-sub-opacity);
  color: var(--canvas-text-color);
  font-size: 16px;
}

.boxBody {
  padding: 10px 40px;
  padding-bottom: 30px;
  padding-top: 0px;
}

.boxBodyTextCallout {
  padding-bottom: 25px;
  padding-top: 25px;
}

.addNewListItemQuill {
  outline: none;
  border-radius: 3px;
  font-weight: 500;
  padding: 12px 15px;
  background: #fff;
  border: var(--canvas-thickness) solid var(--canvas-border);
  /* box-shadow: inset 0 0 0 3px #71809610, 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06); */
  /* background-color: #a0aec005; */
  color: #2b2f35;
  font-size: 16px;
}

/* tasks */

.taskWrapper {
  cursor: inherit !important;
}

.taskIsComplete {
  opacity: 0.5;
  border-style: dashed;
  text-decoration: line-through;
}

/* card detail modal */

.cardModal {
  width: 600px;
  height: 600px;
  border: none;
}

.detailModalQuill {
  border: none;
}

.editListItemTitle:hover,
.editListItemDesc:hover {
  box-shadow: inset 0 -2px 0 #00000020;
  /* border-bottom: 2px dashed #00000020; */
}

.cardModalActions {
  width: 310px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: inset 3px 0 0 rgba(0, 0, 0, 0.05);
}

.cardActionTitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.editListItemDesc[placeholder]:empty:before,
.editListItemDesc[placeholder]:empty:before {
  content: attr(placeholder);
  opacity: 0.25;
}

.sectionIconNoBG {
  background-color: transparent !important;
}

.sectionWrapperEntire {
  /* background-color: var(--canvas-inner-shadow); */
}

.sectionWrapper {
  border-top: 1px solid var(--canvas-border);
  border-left: 1px solid var(--canvas-border);
  background-color: #fff;
  /* box-shadow: 0 0 0 5px var(--canvas-inner-shadow), 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); */
  /* border-radius: 6px; */
  /* overflow: hidden; */
}

.sectionWrapperEntire
  .sectionWrapperSection:first-child
  .grid
  .sectionWrapperSectionInner:last-child
  .sectionWrapperBox {
  /* background-color: #71809680; */
  /* border-top-right-radius: 6px; */
}

.sectionWrapperEntire
  .sectionWrapperSection:last-child
  .grid
  .sectionWrapperSectionInner:first-child
  .sectionWrapperBox {
  /* border-bottom-left-radius: 6px; */
}

.sectionWrapperEntire
  .sectionWrapperSection:last-child
  .grid
  .sectionWrapperSectionInner:last-child
  .sectionWrapperBox {
  /* background-color: #71809680; */
  /* border-bottom-right-radius: 6px; */
}

.sectionWrapperEntire .sectionImage {
  border-top-right-radius: 0px;
  border-bottom: 1px solid var(--canvas-border);
  border-right: 1px solid var(--canvas-border);
  width: 100% !important;
}

.sectionWrapperEntire .sectionTableRowItem,
.sectionWrapperEntire .sectionTableColItem {
  box-shadow: var(--canvas-block-section-shadow) !important;
}

.sectionWrapperEntire
  .sectionWrapperSection:last-child
  .grid
  .sectionWrapperSectionInner:last-child
  .sectionImage {
  border-bottom-right-radius: 6px;
}

.sectionWrapperEntire
  .sectionWrapperSection:last-child
  .grid
  .sectionWrapperSectionInner:first-child
  .sectionImage {
  border-bottom-left-radius: 6px;
}

.sectionWrapperSection .boxTitleBlock {
  padding: 25px 30px;
  padding-bottom: 10px;
}

.sectionWrapperSection .boxBody {
  padding: 10px 30px;
  padding-bottom: 30px;
}

.boxBodyWrapper {
  margin-bottom: 20px;
}

.sectionWrapperBox {
  box-shadow: var(--canvas-block-section-shadow);
  min-height: 75px;
  /* background-color: var(--canvas-inner-shadow); */
}

/* BLUE */
.sectionWrapperBlue {
  border-top: 1px solid #2563eb;
  border-left: 1px solid #2563eb;
  /* box-shadow: 0 0 0 5px #93C5FD30, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}
.sectionWrapperBlue .sectionWrapperBox {
  /* background-color: #93C5FD30; */
  box-shadow: inset -1px 0 0 #2563eb, inset 0 -1px 0 #2563eb,
    inset 0 0 0 3px #93c5fd30;
}
.sectionWrapperBlue .sectionTableRowItem {
  background-color: #93c5fd30;
  box-shadow: inset -1px 0 0 #2563eb, inset 0 -1px 0 #2563eb,
    inset 0 0 0 3px #93c5fd30 !important;
}
.sectionWrapperBlue .sectionTableColItem {
  box-shadow: inset -1px 0 0 #2563eb, inset 0 -1px 0 #2563eb,
    inset 0 0 0 3px #93c5fd30 !important;
}
.sectionWrapperBlue .addTaskBtnBig {
  font-size: 18px;
  color: #2563eb;
  border: var(--canvas-thickness) solid #2563eb;
  box-shadow: 0 0 0 3px #93c5fd30;
  background-color: #fff;
}
.sectionWrapperBlue .addTaskBtnBig:hover {
  color: #fff;
  background-color: #2563eb;
}
.sectionWrapperBlue .addTaskBtnBig .taskBtnPlus {
  background-color: #2563eb20;
}
/* .sectionWrapperBlue .listCardItem{
  border: 1px solid #2563EB60;
  box-shadow: 0 10px 15px -3px #93C5FD30,0 4px 6px -2px #93C5FD30;
}
.sectionWrapperBlue .listCardItem:hover{
  border: 1px solid #2563EB60;
  box-shadow: 0 5px 5px -3px #93C5FD30, 0 4px 6px -2px #93C5FD30;
} */
/* EOA */

/* GREEN */
.sectionWrapperGreen {
  border-top: 1px solid #059669;
  border-left: 1px solid #059669;
  /* box-shadow: 0 0 0 5px #A7F3D030, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}
.sectionWrapperGreen .sectionWrapperBox {
  /* background-color: #A7F3D030; */
  box-shadow: inset -1px 0 0 #059669, inset 0 -1px 0 #059669,
    inset 0 0 0 3px #a7f3d050;
}
.sectionWrapperGreen .sectionTableRowItem {
  background-color: #a7f3d030;
  box-shadow: inset -1px 0 0 #059669, inset 0 -1px 0 #059669,
    inset 0 0 0 3px #a7f3d050 !important;
}
.sectionWrapperGreen .sectionTableColItem {
  box-shadow: inset -1px 0 0 #059669, inset 0 -1px 0 #059669,
    inset 0 0 0 3px #a7f3d050 !important;
}
.sectionWrapperGreen .addTaskBtnBig {
  font-size: 18px;
  color: #059669;
  border: var(--canvas-thickness) solid #059669;
  box-shadow: 0 0 0 3px #a7f3d050;
  background-color: #fff;
}
.sectionWrapperGreen .addTaskBtnBig:hover {
  color: #fff;
  background-color: #059669;
}

.sectionWrapperGreen .addTaskBtnBig .taskBtnPlus {
  background-color: #05966920;
}
/* .sectionWrapperGreen .listCardItem{
  border: 1px solid #05966960;
  box-shadow: 0 10px 15px -3px #A7F3D050,0 4px 6px -2px #A7F3D050;
}
.sectionWrapperGreen .listCardItem:hover{
  border: 1px solid #05966960;
  box-shadow: 0 5px 5px -3px #A7F3D050, 0 4px 6px -2px #A7F3D050;
} */
/* EOA */

/* RED */
.sectionWrapperRed {
  border-top: 1px solid #b91c1c;
  border-left: 1px solid #b91c1c;
  /* box-shadow: 0 0 0 5px #FCA5A530, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}
.sectionWrapperRed .sectionWrapperBox {
  /* background-color: #FCA5A530; */
  box-shadow: inset -1px 0 0 #b91c1c, inset 0 -1px 0 #b91c1c,
    inset 0 0 0 3px #fca5a530;
}
.sectionWrapperRed .sectionTableRowItem {
  background-color: #fca5a530;
  box-shadow: inset -1px 0 0 #b91c1c, inset 0 -1px 0 #b91c1c,
    inset 0 0 0 3px #fca5a530 !important;
}
.sectionWrapperRed .sectionTableColItem {
  box-shadow: inset -1px 0 0 #b91c1c, inset 0 -1px 0 #b91c1c,
    inset 0 0 0 3px #fca5a530 !important;
}
.sectionWrapperRed .addTaskBtnBig {
  font-size: 18px;
  color: #b91c1c;
  border: var(--canvas-thickness) solid #b91c1c;
  box-shadow: 0 0 0 3px #fca5a530;
  background-color: #fff;
}
.sectionWrapperRed .addTaskBtnBig:hover {
  color: #fff;
  background-color: #b91c1c;
}
.sectionWrapperRed .addTaskBtnBig .taskBtnPlus {
  background-color: #b91c1c20;
}
/* .sectionWrapperRed .listCardItem{
  border: 1px solid #B91C1C60;
  box-shadow: 0 10px 15px -3px #FCA5A530,0 4px 6px -2px #FCA5A530;
}
.sectionWrapperRed .listCardItem:hover{
  border: 1px solid #B91C1C60;
  box-shadow: 0 5px 5px -3px #FCA5A530, 0 4px 6px -2px #FCA5A530;
} */
/* EOA */

/* YELLOW */
.sectionWrapperYellow {
  border-top: 1px solid #d97706;
  border-left: 1px solid #d97706;
  /* box-shadow: 0 0 0 5px #FDE68A50, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}
.sectionWrapperYellow .sectionWrapperBox {
  /* background-color: #FDE68A30; */
  box-shadow: inset -1px 0 0 #d97706, inset 0 -1px 0 #d97706,
    inset 0 0 0 3px #fde68a30;
}
.sectionWrapperYellow .sectionTableRowItem {
  background-color: #fde68a30;
  box-shadow: inset -1px 0 0 #d97706, inset 0 -1px 0 #d97706,
    inset 0 0 0 3px #fde68a30 !important;
}
.sectionWrapperYellow .sectionTableColItem {
  box-shadow: inset -1px 0 0 #d97706, inset 0 -1px 0 #d97706,
    inset 0 0 0 3px #fde68a30 !important;
}
.sectionWrapperYellow .addTaskBtnBig {
  font-size: 18px;
  color: #d97706;
  border: var(--canvas-thickness) solid #d97706;
  box-shadow: 0 0 0 3px #fde68a30;
  background-color: #fff;
}
.sectionWrapperYellow .addTaskBtnBig:hover {
  color: #fff;
  background-color: #d97706;
}
.sectionWrapperYellow .addTaskBtnBig .taskBtnPlus {
  background-color: #d9770620;
}
/* .sectionWrapperYellow .listCardItem{
  border: 1px solid #D9770660;
  box-shadow: 0 10px 15px -3px #FDE68A30,0 4px 6px -2px #FDE68A30;
}
.sectionWrapperYellow .listCardItem:hover{
  border: 1px solid #D9770660;
  box-shadow: 0 5px 5px -3px #FDE68A30, 0 4px 6px -2px #FDE68A30;
} */
/* EOA */

/* PURPLE */
.sectionWrapperPurple {
  border-top: 1px solid #6d28d9;
  border-left: 1px solid #6d28d9;
  /* box-shadow: 0 0 0 5px #DDD6FE30, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}
.sectionWrapperPurple .sectionWrapperBox {
  /* background-color: #DDD6FE30; */
  box-shadow: inset -1px 0 0 #6d28d9, inset 0 -1px 0 #6d28d9,
    inset 0 0 0 3px #ddd6fe30;
}
.sectionWrapperPurple .sectionTableRowItem {
  background-color: #ddd6fe30;
  box-shadow: inset -1px 0 0 #6d28d9, inset 0 -1px 0 #6d28d9,
    inset 0 0 0 3px #ddd6fe30 !important;
}
.sectionWrapperPurple .sectionTableColItem {
  box-shadow: inset -1px 0 0 #6d28d9, inset 0 -1px 0 #6d28d9,
    inset 0 0 0 3px #ddd6fe30 !important;
}
.sectionWrapperPurple .addTaskBtnBig {
  font-size: 18px;
  color: #6d28d9;
  border: var(--canvas-thickness) solid #6d28d9;
  box-shadow: 0 0 0 3px #ddd6fe30;
  background-color: #fff;
}
.sectionWrapperPurple .addTaskBtnBig:hover {
  color: #fff;
  background-color: #6d28d9;
}
.sectionWrapperPurple .addTaskBtnBig .taskBtnPlus {
  background-color: #6d28d920;
}
/* .sectionWrapperPurple .listCardItem{
  border: 1px solid #6D28D960;
  box-shadow: 0 10px 15px -3px #DDD6FE30,0 4px 6px -2px #DDD6FE30;
}
.sectionWrapperPurple .listCardItem:hover{
  border: 1px solid #6D28D960;
  box-shadow: 0 5px 5px -3px #DDD6FE30, 0 4px 6px -2px #DDD6FE30;
} */
/* EOA */

/* GRAY */
.sectionWrapperGray {
  border-top: 1px solid #4b5563;
  border-left: 1px solid #4b5563;
  /* box-shadow: 0 0 0 5px #E5E7EB30, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}
.sectionWrapperGray .sectionWrapperBox {
  background-color: #e5e7eb30;
  box-shadow: inset -1px 0 0 #4b5563, inset 0 -1px 0 #4b5563,
    inset 0 0 0 3px #e5e7eb30;
}
.sectionWrapperGray .sectionTableRowItem {
  background-color: #e5e7eb30;
  box-shadow: inset -1px 0 0 #4b5563, inset 0 -1px 0 #4b5563,
    inset 0 0 0 3px #e5e7eb30 !important;
}
.sectionWrapperGray .sectionTableColItem {
  box-shadow: inset -1px 0 0 #4b5563, inset 0 -1px 0 #4b5563,
    inset 0 0 0 3px #e5e7eb30 !important;
}
.sectionWrapperGray .addTaskBtnBig {
  font-size: 18px;
  color: #4b5563;
  border: var(--canvas-thickness) solid #4b5563;
  box-shadow: 0 0 0 3px #e5e7eb30;
  background-color: #fff;
}
.sectionWrapperGray .addTaskBtnBig:hover {
  color: #fff;
  background-color: #4b5563;
}
.sectionWrapperPurple .addTaskBtnBig .taskBtnPlus {
  background-color: #4b556320;
}
/* .sectionWrapperGray .listCardItem{
  border: 1px solid #4B556360;
  box-shadow: 0 10px 15px -3px #E5E7EB30,0 4px 6px -2px #E5E7EB30;
}
.sectionWrapperGray .listCardItem:hover{
  border: 1px solid #4B556360;
  box-shadow: 0 5px 5px -3px #E5E7EB30, 0 4px 6px -2px #E5E7EB30;
} */
/* EOA */

.sectionWrapperBox .titleText {
  font-size: 27px;
  font-weight: 500;
}

.bg-skew-to-fit {
  background-size: 100% 100%;
}

.selectedMultiOption {
  color: #fff !important;
}

.resourceCard {
  border: 1px solid #71809680;
  /* box-shadow: inset 0 0 0 3px #71809610; */
}

/* Styles for Rich Text Content */

.ql-toolbar.ql-snow {
  background: #eeeeee;
}

.richTextStyles p {
  margin-bottom: 12px;
}

.richTextStyles img {
  margin-bottom: 12px;
}

.richTextStyles h1,
.editRichTextQuill .ql-editor h1 {
  font-size: 22px;
  margin-bottom: 12px;
  font-weight: bold;
}

.richTextStyles h2,
.editRichTextQuill .ql-editor h2 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
}

.richTextStyles h3,
.editRichTextQuill .ql-editor h3 {
  font-weight: 500;
  margin-bottom: 12px;
}

.richTextStyles blockquote,
.editRichTextQuill .ql-editor blockquote {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
  border-left: 3px solid #ddd;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.richTextStyles blockquote p {
  margin-bottom: 0px;
}

.richTextStyles a,
.editRichTextQuill .ql-editor a {
  color: rgb(37, 99, 235);
  text-decoration: underline;
}

.richTextStyles ul,
.richTextStyles ol,
.editRichTextQuill .ql-editor ul,
.editRichTextQuill .ql-editor ol {
  margin-bottom: 12px;
  margin-left: 20px;
  padding-left: 0px;
}

.editRichTextQuill .ql-editor ul,
.editRichTextQuill .ql-editor ol {
  margin-left: 0px;
}

.editRichTextQuill .ql-editor li {
  margin-bottom: 5px;
}

.richTextStyles li {
  list-style: disc;
  margin-bottom: 8px;
}

.richTextStyles li.ql-indent-1 {
  list-style: circle;
  margin-left: 20px;
}

.richTextStyles ol li {
  list-style: decimal;
  margin-bottom: 8px;
}

.richTextStyles ol li.ql-indent-1 {
  list-style: disc;
  margin-left: 20px;
}

.editRichTextQuill .ql-editor p {
  margin-bottom: 15px;
}

/* .editRichTextQuill .ql-video{
  width: 100%;
  position: relative;
  padding-top: 56.25%;
} */

.sectionLongText {
  resize: none;
  font-size: 22px;
  /* border-left: 2px solid var(--canvas-border); */
  /* padding-left: 15px; */
  /* padding-bottom: 10px; */
  /* padding-top: 10px; */
  background: transparent;
  border: none;
}

.themePreviewBox {
  height: 50px;
  background: var(--override_lower_color);
  border: var(--canvas-thickness) solid var(--canvas-border);
  border-radius: 3px;
  overflow: hidden;
}

.themePreviewBoxGrid {
  height: 50px;
  background: var(--override_lower_color);
  border: var(--canvas-thickness) solid var(--canvas-border);
  border-radius: 3px;
  overflow: hidden;
  box-shadow: inset 0 0 0 4px var(--canvas-inner-shadow) !important;
}

.themePreviewBoxHeader {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 50%;
  width: 100%;
  /* border-top-left-radius: 3px; */
  /* border-top-right-radius: 3px; */
}

.themePreviewBoxLower {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 50%;
  width: 100%;
  background: var(--overall-bg-color);
  box-shadow: inset 0 var(--canvas-thickness) 0 var(--canvas-border);
  /* border-bottom-left-radius: 3px; */
  /* border-bottom-right-radius: 3px; */
}

.splitView .themePreviewBoxHeader,
.splitView .themePreviewBoxLower {
  height: 100%;
  width: 50%;
}

.splitView .themePreviewBoxHeader {
  top: 0px;
  left: 0px;
  border-radius: 0px;
}

.splitView .themePreviewBoxLower {
  top: 0px;
  right: 0px;
  left: auto;
  box-shadow: inset var(--canvas-thickness) 0 0 var(--canvas-border);
  border-radius: 0px;
}

.themeInnerBox {
  height: 80%;
  width: 80%;
  background: #fff;
  border-radius: 6px;
  z-index: 2;
  border: var(--canvas-thickness) solid var(--canvas-border);
  box-shadow: inset 0 0 0 4px var(--canvas-inner-shadow);
}

.customRemove {
  right: -10px;
  top: -10px;
  border: 2px solid #000;
}

.designSlideScreen div[data-baseweb='tab-list'] button {
  font-size: 18px;
}

.sectionTitleColorPicker {
  box-shadow: inset 0 0 0 2px #00000020;
  border-radius: 6px;
}

.sectionImageOpacity [data-baseweb='slider'] {
  margin-left: -15px;
}

.slideoutContainerMedia {
  z-index: 99999;
  width: 410px;
  position: fixed;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),
    var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow),
    0 0 0 40px #00000040;
}

.slideoutContainerThemes {
  z-index: 99999;
  width: 750px;
  position: fixed;
  /* box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),
    var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow),
    0 0 0 40px #00000040; */
}

/* .templateDesignCard:hover {
  background-size: 200% !important;
  background-position-x: -10px;
  background-position-y: -100px;
} */

.explainerModalWrapper {
  z-index: 990;
}

.bgOPNav {
  /* box-shadow: inset 0 -4px 0 var(--canvas-inner-shadow);
  border-bottom: 1px solid var(--canvas-border); */
  /* height: 44px; */
  /* box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.05); */
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.collectionSideBar {
  /* width: 450px; */
}

.collectionSideBarItemTitle {
  font-family: 'Pensum';
}

.imgRoundWithPadding {
  margin: 20px !important;
  background-image: none !important;
}
.sectionWrapperBox .imgRoundWithPadding {
  margin: 0px !important;
  padding: 20px;
}

.imgFullsize {
  background-image: none !important;
}

/* .flipCardFront {

}

.flipCardBack {
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.05),
    0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
} */

.flipCardSection .react-card-front {
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.flipCardSection .react-card-back {
  border-radius: 6px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.07),
    0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

.flipCardInner {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.05);
}

.tiptapWrapper .ProseMirror {
  outline: none;
}

.tipTapToolBar {
  display: none;
  margin-left: 40px;
  margin-right: 40px;
  /* width: calc(100% - 80px); */
  top: -27px;
  padding: 3px 5px;
}

.boxBodyTextEditor:focus-within .tipTapToolBar {
  display: flex;
}

.tipTapToolBar .is-active {
  color: #006aff;
}

.editSectionWrapper .ProseMirror {
  font-size: 17px;
}

.editSectionWrapper .ProseMirror ul,
.editSectionWrapper .ProseMirror ol {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.editSectionWrapper .ProseMirror ul li,
.editSectionWrapper .ProseMirror ol li {
  line-height: 1.8rem;
  margin-left: 30px;
}

.editSectionWrapper .ProseMirror ul li {
  list-style: disc;
}

.editSectionWrapper .ProseMirror ol li {
  list-style: decimal;
}

.editSectionWrapper .ProseMirror blockquote {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  border-left: 2px solid #ddd;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.editSectionWrapper .ProseMirror p {
  line-height: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 0;
}

.editSectionWrapper .ProseMirror h1,
.editSectionWrapper .ProseMirror h2 {
  display: block;
  font-size: 21px;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.editSectionWrapper .ProseMirror ul[data-type='taskList'] {
  list-style: none;
  padding: 0;
}

.editSectionWrapper .ProseMirror ul[data-type='taskList'] li {
  display: flex;
  align-items: center;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.editSectionWrapper .ProseMirror ul[data-type='taskList'] li > input {
  flex: 0 0 auto;
  margin-right: 0.5rem;
}

.editSectionWrapper
  .ProseMirror
  ul[data-type='taskList']
  li[data-checked='true'] {
  opacity: 0.5;
  text-decoration: line-through;
}

.editSectionWrapper .ProseMirror ul[data-type='taskList'] p {
  margin-top: 0px;
}

.editSectionWrapper .ProseMirror p:first-child,
.editSectionWrapper .ProseMirror h2:first-child {
  margin-top: 0px;
}

.editSectionWrapper .ProseMirror:empty::after {
  content: 'Empty';
}

/* TEMPLATE PREVIEWER -- hide top bar and lower area */

.template-previewer .lowerBanner,
.template-previewer .bgOPNav {
  display: none !important;
}

/* FLOATING SECTIONS THEME */

.floatingGrid .boxes {
  gap: 20px !important;
  background: transparent !important;
  box-shadow: none !important;
}

.floatingGrid .box {
  border-radius: 6px !important;
  border: var(--canvas-thickness) solid var(--canvas-border) !important;
  box-shadow: inset -2px 0 0 var(--canvas-border),
    inset 0 -2px 0 var(--canvas-border),
    inset -1px 0 0 5px var(--canvas-inner-shadow) !important;
}

/* FLOATING SECTIONS THEME - SIMPLE */

.floatingGridSimple .boxes {
  gap: 20px !important;
  background: transparent !important;
  box-shadow: none !important;
}

.floatingGridSimple .box {
  border-radius: 6px !important;
  border: var(--canvas-thickness) solid var(--canvas-border) !important;
  box-shadow: inset 0 0 0 3px var(--canvas-inner-shadow),
    0 10px 15px -5px rgba(0, 0, 0, 0.05), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  /* margin-right: 0px !important; */
  /* padding-right: 0px !important; */
  /* margin-bottom: 0px !important; */
}

.floatingGridSimple .box.hasCustomBGColor {
  border: var(--canvas-thickness) solid var(--canvas-border) !important;
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.05),
    0 10px 15px -5px rgba(0, 0, 0, 0.05), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

.floatingGridSimple .box .sectionImage {
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  width: 100% !important;
  border-radius: 6px !important;
}

.floatingGridSimple .skeletonGrid {
  border-radius: 6px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  box-shadow: none !important;
}

/* .floatingGridSimple .sectionWrapperSection .grid {
  grid-gap: 15px;
}

.floatingGridSimple .sectionWrapper {
  @apply space-y-5;
}

.floatingGridSimple .sectionWrapperSection .sectionWrapperBox {
  border-radius: 6px !important;
  border: var(--canvas-thickness) solid var(--canvas-border) !important;
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.05),
    0 10px 15px -5px rgba(0, 0, 0, 0.05), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
} */

.floatingGridSimple .sectionTableRowItem:last-of-type {
  box-shadow: var(--canvas-block-table-shadow-custom-bg-floating);
}

.floatingGridSimple .sectionTableRowItem:last-of-type {
  border-top-right-radius: 6px;
}

.floatingGridSimple .sectionTableRowItem:first-of-type {
  border-top-left-radius: 6px;
}

.floatingGridSimple .sectionTableColItem:last-of-type {
  box-shadow: var(--canvas-table-cell-shadow-custom-bg);
}

.sectionType_video {
  padding-right: var(--canvas-thickness);
  padding-bottom: var(--canvas-thickness);
}

.floatingGridSimple .sectionType_video {
  padding-right: 0px;
  padding-bottom: 0px;
}

/* NO HEADER */

.noHeaderGrid {
  margin-top: 50px !important;
}

.noHeaderHero .heroInner {
  display: none !important;
}

/* WHITE TEXT OVERRIDES */

.hasCustomBGColorInhert {
  background-color: var(--canvas-section-custom-bg-toplevel) !important;
}

.sectionWhiteText,
.sectionWhiteText .box {
  color: #fff;
}

/* SECTION BACKGROUNDS OVERRIDES */

.sectionTopLevelBGColor .box {
  background-color: var(--canvas-section-custom-bg-toplevel);
  box-shadow: var(--canvas-block-shadow-custom-bg);
}

.sectionTopLevelBGColor .boxes {
  background-color: transparent;
}

.floatingGridSimple.sectionTopLevelBGColor .box {
  background-color: var(--canvas-section-custom-bg-toplevel);
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.05),
    0 10px 15px -5px rgba(0, 0, 0, 0.05), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

.sectionWhiteText .multiChoiceButton {
  background-color: rgba(0, 0, 0, 0.2);
}

.sectionWhiteText .addTaskBtnBig {
  background-color: rgba(0, 0, 0, 0.2);
}
.sectionWhiteText .addTaskBtnBig .taskBtnPlus {
  background-color: rgba(0, 0, 0, 0.3);
}

.sectionWhiteText .listCardItem {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.sectionWhiteText .TaskItemText {
  color: #fff;
}

.sectionWhiteText .taskTickBox {
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: #fff;
}

.sectionWhiteText .addNewListItemQuill {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.sectionWhiteText .newTaskItem {
  background-color: transparent;
}

.sectionWhiteText .sectionTableColItem:focus-within {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.sectionWhiteText .sectionWrapper {
  background-color: rgba(0, 0, 0, 0.2);
}

.sectionWhiteText .sectionWrapperBox {
  box-shadow: var(--canvas-block-section-shadow-custom-bg);
}

.sectionWhiteText .resourceCard {
  background-color: rgba(0, 0, 0, 0.2);
}

.sectionWhiteText .resourceCard:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/* .sectionWhiteText .multiChoiceButtonSelected {
  background-color: auto;
} */

.fullSizeBackground {
  /* background-color: var(--header-bg-color); */
}

.fullSizeBackground .hero {
  /* background-color: transparent; */
}

.fullSizeBackground.hero::after {
  position: fixed;
  width: 100%;
  height: 100%;
}

.sectionWhiteText .skeletonGrid {
  border-color: rgba(255, 255, 255, 0.3);
}

.sectionTopLevelBGColor .gridLeftEdge {
  box-shadow: inset 4px 0 0 rgba(0, 0, 0, 0.2) !important;
}

.sectionTopLevelBGColor .gridTopEdge {
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.2) !important;
}

.sectionTopLevelBGColor .gridBottomEdge {
  box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.2) !important;
}

.sectionTopLevelBGColor .gridRightEdge {
  box-shadow: inset -4px 0 0 rgba(0, 0, 0, 0.2) !important;
}

.sectionTopLevelBGColor .gridTopLeftCorner {
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.2), inset 4px 0 0 rgba(0, 0, 0, 0.2) !important;
}

.sectionTopLevelBGColor .gridTopRightCorner {
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.2),
    inset -4px 0 0 rgba(0, 0, 0, 0.2) !important;
}

.sectionTopLevelBGColor .gridBottomRightCorner {
  box-shadow: inset -4px 0 0 rgba(0, 0, 0, 0.2),
    inset 0 -4px 0 rgba(0, 0, 0, 0.2) !important;
}

.sectionTopLevelBGColor .gridBottomLeftCorner {
  box-shadow: inset 4px 0 0 rgba(0, 0, 0, 0.2),
    inset 0 -4px 0 rgba(0, 0, 0, 0.2) !important;
}

/* .sectionTopLevelBGColor .skeletonGrid.ui-selected,
.sectionTopLevelBGColor .skeletonGrid.ui-selecting {
  background-color: rgb(101, 173, 232) !important;
  border-color: rgb(9, 90, 155) !important;
} */

.videoSectionWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.videoSectionPaddingRounded .react-player {
  border-radius: 6px !important;
  overflow: hidden;
}

.boxHeadline {
  padding: 30px 30px;
}

.sectionType_headline,
.sectionType_video {
  min-height: 0;
}

.cardEmbedGrid .miniGridPreviewEmbed {
  /* max-height: 160px; */
  height: 160px;
}

.ui-selecting.boxNoGo {
  background-color: rgba(228, 56, 30, 0.4) !important;
}

.removeError {
  background-color: rgba(228, 56, 30, 1) !important;
}

/* .removeError:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(228, 56, 30, 1) !important;
} */

.onepagerCanvasWrapper {
  border-radius: 6px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%),
    0 10px 10px -5px rgb(0 0 0 / 4%), inset 0 0 0 3px var(--canvas-inner-shadow);
  background: #fff;
  border: var(--canvas-thickness) solid var(--canvas-border);
}
