.shortTextAnswer {
  /* font-size: 30px; */
  font-size: 22px;
  padding-bottom: 8px;
  border-radius: 0px;
  /* box-shadow: var(--canvas-border) 0px 2px; */
  border-bottom: 2px solid var(--canvas-border);
  width: 100%;
  font-weight: 500;
  background: transparent;
  /* background: #dddddd20; */
  /* padding: 5px 10px;   */
  border-left: none;
  border-top: none;
  border-right: none;
}

.shortTextAnswerBlue {
  /* box-shadow: rgb(0, 106, 255) 0px 2px; */
  border-color: rgb(0, 106, 255);
  background: rgb(0 106 255 / 4%);
  color: rgb(32, 48, 72);
  padding: 5px 10px;
}

.shortTextAnswerGreen {
  /* box-shadow: #10b981 0px 2px; */
  border-color: #10b981;
  background: #a7f3d020;
  color: #065f46;
  padding: 5px 10px;
}

.shortTextAnswerRed {
  /* box-shadow: #dc2626 0px 2px; */
  border-color: #dc2626;
  background: #fecaca20;
  color: #991b1b;
  padding: 5px 10px;
}

.shortTextAnswerPurple {
  /* box-shadow: #7c3aed 0px 2px; */
  border-color: #7c3aed;
  background: #ddd6fe20;
  color: #5b21b6;
  padding: 5px 10px;
}

.shortTextAnswerYellow {
  /* box-shadow: #f59e0b 0px 2px; */
  border-color: #f59e0b;
  background: #fde68a20;
  color: #92400e;
  padding: 5px 10px;
}

.shortTextAnswerGray {
  /* box-shadow: #6b7280 0px 2px; */
  border-color: #6b7280;
  background: #f3f4f6;
  color: #374151;
  padding: 5px 10px;
}

/* .shortTextAnswer:empty::before {
  content: attr(placeholder);
  color: #00000050 !important;
} */

.shortTextAnswer::placeholder,
.sectionLongText::placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
}

.sectionWhiteText .shortTextAnswer::placeholder,
.sectionWhiteText .sectionLongText::placeholder {
  color: rgba(255, 255, 255, 0.4) !important;
}
