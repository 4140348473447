.taskWrapper:hover .sortHandle,
.taskWrapper:hover .removeHandle {
  opacity: 1;
}

.taskWrapper:hover .TaskItemText {
  /* opacity: 0.7; */
}

.TaskItemText {
  font-size: 18px;
  border: none;
  outline: none !important;
}

.editListItemTitle,
.editListItemDesc{
  border: none;
  outline: none !important;
}

.addTaskBtn {
  font-size: 14px;
  /* border: var(--canvas-thickness) solid var(--canvas-border); */
  /* background-color: var(--canvas-inner-shadow); */
}

.addTaskBtn:hover {
  background-color: var(--canvas-border);
}

.addTaskBtnBig {
  font-size: 18px;
  border: var(--canvas-thickness) solid var(--canvas-border);
  /* box-shadow: 0 0 0 3px var(--canvas-inner-shadow); */
  background-color: #fff;
  padding: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-right: 0.75rem;
}

.addTaskBtnBig .taskBtnPlus {
  background-color: var(--canvas-inner-shadow);
  margin-top: 1px;
}

.addTaskBtnBig:hover .taskBtnPlus {
  background-color: #00000010;
}

.listItemColorGreen {
  color: #047857 !important;
  /* border: 1px solid #38a16980 !important; */
  /* background-color: #F0FDF4 !important; */
  /* box-shadow: inset 0 0 0 3px #38a16910, 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important; */
}

.listCardItem.listItemColorGreen {
  border: 1px solid #01010114 !important;
  background-color: #38a169 !important;
  color: #fff !important;
  /* box-shadow: inset 0 0 0 3px #01010107 !important; */
}

.listCardItem.listItemColorGreen.taskIsComplete {
  background-color: #fff !important;
  border: 1px solid #38a16980 !important;
  box-shadow: inset 0 0 0 3px #38a16920 !important;
  color: #38a169 !important;
}

.listItemColorGreen .taskTickBox {
  border: 2px solid #38a169 !important;
  /* background-color: #fff !important; */
}

.listItemColorBlue {
  color: #206bb1 !important;
  /* border: 1px solid #3182ce80 !important; */
  /* background-color: #F0F9FF !important; */
  /* box-shadow: inset 0 0 0 3px #e53e3e10, 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important; */
}

/* .listCardItem.listItemColorBlue{
  border: 1px solid #3182ce80 !important;
  background-color: #F0F9FF !important;
  box-shadow: inset 0 0 0 3px #3182ce10 !important;
} */

.listCardItem.listItemColorBlue {
  border: 1px solid #01010114 !important;
  background-color: #3c81f5 !important;
  color: #fff !important;
  /* box-shadow: inset 0 0 0 3px #01010107 !important; */
}

.listCardItem.listItemColorBlue.taskIsComplete {
  background-color: #fff !important;
  border: 1px solid #3c81f580 !important;
  box-shadow: inset 0 0 0 3px #3c81f520 !important;
  color: #3c81f5 !important;
}

.listItemColorBlue .taskTickBox {
  border: 2px solid #3182ce !important;
  /* background-color: #fff !important; */
}

.listItemColorRed {
  color: #991b1b !important;
  /* border: 1px solid #e53e3e80 !important; */
  /* background-color: #FEF2F2 !important; */
  /* box-shadow: inset 0 0 0 3px #e53e3e10, 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important; */
}

/* .listCardItem.listItemColorRed{
  border: 1px solid #e53e3e80 !important;
  background-color: #FEF2F2 !important;
  box-shadow: inset 0 0 0 3px #e53e3e10 !important;
} */

.listCardItem.listItemColorRed {
  border: 1px solid #01010114 !important;
  background-color: #e53e3e !important;
  color: #fff !important;
  /* box-shadow: inset 0 0 0 3px #01010107 !important; */
}

.listCardItem.listItemColorRed.taskIsComplete {
  background-color: #fff !important;
  border: 1px solid #e53e3e80 !important;
  box-shadow: inset 0 0 0 3px #e53e3e20 !important;
  color: #e53e3e !important;
}

.listItemColorRed .taskTickBox {
  border: 2px solid #e53e3e !important;
  background-color: #fff !important;
}

.listItemColorPurple {
  color: #6b21a8 !important;
  /* border: 1px solid #805ad580 !important; */
  /* background-color: #FAF5FF !important; */
  /* box-shadow: inset 0 0 0 3px #805ad510, 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important; */
}

/* .listCardItem.listItemColorPurple{
  border: 1px solid #805ad580 !important;
  background-color: #FAF5FF !important;
  box-shadow: inset 0 0 0 3px #805ad510!important;
} */

.listCardItem.listItemColorPurple {
  border: 1px solid #01010114 !important;
  background-color: #805ad5 !important;
  color: #fff !important;
  /* box-shadow: inset 0 0 0 3px #01010107 !important; */
}

.listCardItem.listItemColorPurple.taskIsComplete {
  background-color: #fff !important;
  border: 1px solid #805ad580 !important;
  box-shadow: inset 0 0 0 3px #805ad520 !important;
  color: #805ad5 !important;
}

.listItemColorPurple .taskTickBox {
  border: 2px solid #805ad5 !important;
  /* background-color: #fff !important; */
}

.listItemColorPink {
  color: #be185d !important;
  /* border: 1px solid #d53f8c80 !important; */
  /* background-color: #FDF2F8 !important; */
  /* box-shadow: inset 0 0 0 3px #d53f8c10, 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important; */
}

/* .listCardItem.listItemColorPink{
  border: 1px solid #d53f8c80 !important;
  background-color: #FDF2F8 !important;
  box-shadow: inset 0 0 0 3px #d53f8c10 !important;
} */

.listCardItem.listItemColorPink {
  border: 1px solid #01010114 !important;
  background-color: #d53f8c !important;
  color: #fff !important;
  /* box-shadow: inset 0 0 0 3px #01010107 !important; */
}

.listCardItem.listItemColorPink.taskIsComplete {
  background-color: #fff !important;
  border: 1px solid #d53f8c80 !important;
  box-shadow: inset 0 0 0 3px #d53f8c20 !important;
  color: #d53f8c !important;
}

.listItemColorPink .taskTickBox {
  border: 2px solid #d53f8c !important;
  /* background-color: #fff !important; */
}

.listItemColorIndigo {
  color: #3730a3 !important;
  /* border: 1px solid #5a67d880 !important; */
  /* background-color: #EEF2FF !important; */
  /* box-shadow: inset 0 0 0 3px #5a67d810, 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important; */
}

/* .listCardItem.listItemColorIndigo{
  border: 1px solid #5a67d880 !important;
  background-color: #EEF2FF !important;
  box-shadow: inset 0 0 0 3px #5a67d810 !important;
} */

.listCardItem.listItemColorIndigo {
  border: 2px solid #01010114 !important;
  background-color: #5a67d8 !important;
  color: #fff !important;
  /* box-shadow: inset 0 0 0 3px #01010107 !important; */
}

.listCardItem.listItemColorIndigo.taskIsComplete {
  background-color: #fff !important;
  border: 2px solid #5a67d880 !important;
  box-shadow: inset 0 0 0 3px #5a67d820 !important;
  color: #5a67d8 !important;
}

.listItemColorIndigo .taskTickBox {
  border: 2px solid #5a67d8 !important;
  /* background-color: #fff !important; */
}

.listItemColorTeal {
  color: #0f766e !important;
  border: 1px solid #38b2ac80 !important;
  background-color: #f0fdfa !important;
  box-shadow: inset 0 0 0 3px #38b2ac10, 0 1px 3px 0 rgba(0, 0, 0, 0.08),
    0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.listItemColorTeal .taskTickBox {
  border: 1px solid #38b2ac80 !important;
  background-color: #fff;
}

.listItemColorOrange {
  color: #dd6b20 !important;
  border: 1px solid #dd6b2080 !important;
  background-color: #fff7ed !important;
  box-shadow: inset 0 0 0 3px #dd6b2010, 0 1px 3px 0 rgba(0, 0, 0, 0.08),
    0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.listItemColorOrange .taskTickBox {
  border: 1px solid #dd6b2080 !important;
  /* background-color: #fff !important; */
}

.listItemColorYellow {
  color: #b45309 !important;
  /* border: 1px solid #d69e2e80 !important; */
  /* background-color: #FFFBEB !important; */
  /* box-shadow: inset 0 0 0 3px #d69e2e10, 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important; */
}

.listCardItem.listItemColorYellow {
  /* border: 1px solid #d69e2e80 !important;
  background-color: #FFFBEB !important;
  box-shadow: inset 0 0 0 3px #d69e2e10 !important; */
  border: 1px solid #01010114 !important;
  background-color: #ffd322 !important;
  color: #604001 !important;
  /* box-shadow: inset 0 0 0 3px #01010107 !important; */
}

.listCardItem.listItemColorYellow.taskIsComplete {
  background-color: #fff !important;
  border: 1px solid #5e4d0b80 !important;
  box-shadow: inset 0 0 0 3px #ffd32280 !important;
}

.listItemColorYellow .taskTickBox {
  border: 2px solid #d69e2e !important;
  /* background-color: #fff !important; */
}

.listItemColorGray {
  color: #374151 !important;
  /* border: 1px solid #71809680 !important; */
  /* background-color: #E2E8F0 !important; */
  /* box-shadow: inset 0 0 0 3px #71809610, 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important; */
}

.listItemColorGray .taskTickBox {
  border: 2px solid #718096 !important;
  /* background-color: #fff !important; */
}

.detailModalQuill .ql-toolbar.ql-snow {
  border: none !important;
  margin-bottom: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'CeraPro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 0px;
}

.detailModalQuill .ql-snow .ql-formats {
  /* background: #FFF !important; */
  border-radius: 3px !important;
  /* padding: 5px !important; */
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important; */
  /* border: 1px solid #9fa6b2; */
}

.detailModalQuill .ql-container.ql-snow {
  border: none !important;
}

.detailModalQuill .ql-editor {
  padding: 10px 0px;
}

.detailModalQuill .ql-editor.ql-blank::before {
  left: 0px;
  font-size: 16px;
  font-style: normal;
  opacity: 0.8;
}

.detailModalQuill .ql-container .ql-editor * {
  line-height: 1.5;
  font-size: 16px;
  margin-bottom: 5px;
  /* color: #4B5563; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'CeraPro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.detailModalQuill .ql-container .ql-editor h1 {
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 10px;
}

.detailModalQuill .ql-container .ql-editor h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.detailModalQuill .ql-container .ql-editor p {
  margin-bottom: 10px;
}

.detailModalQuill .ql-container .ql-editor ul {
  margin-bottom: 20px;
  /* padding-left: 0px; */
}

.detailModalQuill .ql-container .ql-editor li {
  margin-bottom: 10px;
}

.detailModalQuill .ql-container .ql-editor ol {
  margin-bottom: 20px;
  /* padding-left: 0px; */
}

.detailModalQuill .ql-container .ql-editor li {
  margin-bottom: 10px;
}
