@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'CeraPro-Regular';
  src: url('fonts/CeraPro-Regular.eot');
  src: url('fonts/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Regular.woff2') format('woff2'),
    url('fonts/CeraPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro-Thin';
  src: url('fonts/CeraPro-Thin.eot');
  src: url('fonts/CeraPro-Thin.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Thin.woff2') format('woff2'),
    url('fonts/CeraPro-Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro-Thin';
  src: url('fonts/CeraPro-ThinItalic.eot');
  src: url('fonts/CeraPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-ThinItalic.woff2') format('woff2'),
    url('fonts/CeraPro-ThinItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro-Light';
  src: url('fonts/CeraPro-Light.eot');
  src: url('fonts/CeraPro-Light.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Light.woff2') format('woff2'),
    url('fonts/CeraPro-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src: url('fonts/CeraPro-Regular.eot');
  src: url('fonts/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Regular.woff2') format('woff2'),
    url('fonts/CeraPro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src: url('fonts/CeraPro-RegularItalic.eot');
  src: url('fonts/CeraPro-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-RegularItalic.woff2') format('woff2'),
    url('fonts/CeraPro-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro';
  src: url('fonts/CeraPro-Medium.eot');
  src: url('fonts/CeraPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Medium.woff2') format('woff2'),
    url('fonts/CeraPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src: url('fonts/CeraPro-MediumItalic.eot');
  src: url('fonts/CeraPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-MediumItalic.woff2') format('woff2'),
    url('fonts/CeraPro-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro';
  src: url('fonts/CeraPro-Bold.eot');
  src: url('fonts/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Bold.woff2') format('woff2'),
    url('fonts/CeraPro-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src: url('fonts/CeraPro-BoldItalic.eot');
  src: url('fonts/CeraPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-BoldItalic.woff2') format('woff2'),
    url('fonts/CeraPro-BoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'CeraPro-Black';
  src: url('fonts/CeraPro-Black.eot');
  src: url('fonts/CeraPro-Black.eot?#iefix') format('embedded-opentype'),
    url('fonts/CeraPro-Black.woff2') format('woff2'),
    url('fonts/CeraPro-Black.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'PensumDEMO';
  src: url('fonts/PensumDEMO-ExtraBold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PensumDEMO';
  src: url('fonts/PensumDEMO-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'tiempos-black';
  font-display: auto;
  font-weight: 700;
  src: url('fonts/tiempos-headline-web-black.woff') format('woff');
}

@font-face {
  font-family: 'tiempos-bold';
  font-display: auto;
  font-weight: 700;
  src: url('fonts/tiempos-headline-web-bold.woff2') format('woff2'),
    url('fonts/tiempos-headline-web-bold.woff') format('woff'),
    url('fonts/tiempos-headline-web-bold.eot') format('opentype');
}

@font-face {
  font-family: 'tiempos-thin';
  font-display: auto;
  font-weight: 400;
  src: url('fonts/tiempos-headline-web-light.woff') format('woff');
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'CeraPro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

button:focus {
  outline: none !important;
}

[role='tabpanel']:focus {
  outline: none !important;
}

[data-ui='Switch'] [data-name='representation'] {
  width: 57px;
  height: 46px;
}

[data-ui='Switch'] [data-name='representation'] span:first-child {
  width: 57px;
  height: 46px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  box-shadow: 0 0 0 3px #e4e6e9;
  cursor: pointer;
}

[data-ui='Switch'] [data-name='representation'] span:last-child {
  height: 36px;
  width: 33px;
  border-radius: 5px;
  top: 5px;
  cursor: pointer;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.42);
}

button[data-ui='Tab'][data-selected] {
  background: #202936;
  color: #ffffff;
  box-shadow: 0 0 0 1px #202936;
}

button[data-ui='Tab'][data-selected]:focus:not(:focus-visible) {
  background: #202936;
  color: #ffffff;
  box-shadow: 0 0 0 1px #202936;
}

.stickyPreview .sticky {
  padding-top: 80px;
}

.mobile-preview-width {
  width: 320px;
}

/* Update Old Browser */

#outdated {
  position: absolute;
  background-color: #ffc800 !important;
  border-bottom: 2px solid #3c1f05 !important;
  color: #3c1f05 !important;
  display: none;
  overflow: hidden;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 1500;
  padding: 44px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

#outdated.fullscreen {
  height: 100%;
}

#outdated .vertical-center {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

#outdated h6 {
  font-size: 36px;
  line-height: 36px;
  font-weight: bold;
  color: #3c1f05 !important;
  margin-bottom: 10px;
}

#outdated p {
  font-size: 17px;
  line-height: 17px;
  color: #3c1f05 !important;
  margin: 0;
}

#outdated #buttonUpdateBrowser {
  border: 2px solid #6c3a0e;
  background-color: #3c1f05 !important;
  color: #fff !important;
  cursor: pointer;
  display: block;
  font-weight: bold;
  font-size: 21px;
  border-radius: 5px;
  margin: 20px auto;
  margin-bottom: 0;
  padding: 10px 20px;
  position: relative;
  text-decoration: none;
  width: 230px;
}

#outdated #buttonUpdateBrowser:hover {
  background-color: white;
  color: #f25648;
}

#outdated .last {
  height: 20px;
  position: absolute;
  right: 70px;
  top: 10px;
  width: auto;
  display: inline-table;
}

#outdated .last[dir='rtl'] {
  left: 25px !important;
  right: auto !important;
}

#outdated #buttonCloseUpdateBrowser {
  color: #3c1f05 !important;
  background-color: #fff;
  border-radius: 100%;
  display: block;
  font-size: 36px;
  padding: 3px 7px;
  height: 100%;
  line-height: 36px;
  border-radius: 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
}
