/*

  Onepager styles - don't want to deal with inline JSX or whatever styles...

*/

/*

  HERO

*/

.hero {
  background-color: var(--header-bg-color);
  /* border-bottom-color: var(--canvas-border); */
  /* border-bottom-width: var(--canvas-thickness); */
  /* border-bottom-style: solid; */
  position: relative;
  /* background-image: var(--header-image);
  background-repeat: var(--header-bg-repeat);
  background-size: var(--header-bg-size);
  background-position: var(--header-bg-position); */
}

.hero::after {
  content: '';
  background-image: var(--header-image);
  background-repeat: var(--header-bg-repeat);
  background-size: var(--header-bg-size);
  background-position: var(--header-bg-position);

  opacity: var(--header-bg-opacity);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}

.heroInner {
  /* padding-bottom: 175px; */
  padding-bottom: 300px;
  color: var(--header-text-color);
  z-index: 2;
  position: relative;
}

.heroBrandingCta {
  border-bottom: 2px solid var(--header-text-color);
  transition: opacity 0.3s ease;
}

.heroTitle {
  /* font-size: 58px; */
  /* font-family: 'CeraPro-Black', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  font-family: var(--header-title-font);
  font-weight: bold;
  transition: opacity 0.3s ease;
  src: url('../fonts/CeraPro-Black.eot');
  src: url('../fonts/CeraPro-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CeraPro-Black.woff2') format('woff2'),
    url('../fonts/CeraPro-Black.woff') format('woff');
}

.heroBlurb {
  /* font-size: 20px; */
  line-height: 1.8;
  transition: opacity 0.3s ease;
}

.heroExpertName {
  /* font-size: 16px; */
  /* border-bottom: 2px solid var(--header-text-color); */
  /* margin-bottom: 3px; */
  /* padding-bottom: 3px; */
}

.heroBranding {
  transition: opacity 0.3s ease;
}

.brandingLogo {
  max-height: 44px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.headerSectionBottomShape {
  position: relative;
  top: 0px;
  left: 0;
  width: 100%;
  /* overflow: hidden; */
  line-height: 0;
  transform: rotate(180deg);
  z-index: 2;
}

.headerSectionBottomShape svg {
  position: relative;
  display: block;
  z-index: 2;
  transform: rotateY(180deg);
  bottom: 1px;
}

.headerSectionBottomShape .shape-fill {
  fill: var(--overall-bg-color);
}

/* section style simple tilt left */
.hsTiltLeft {
  position: relative;
  top: 0px;
  left: 0;
  width: 100%;
  /* overflow: hidden; */
  line-height: 0;
  transform: rotate(180deg);
  z-index: 2;
}

.hsTiltLeft svg {
  position: relative;
  display: block;
  z-index: 2;
  transform: rotateY(0deg);
  bottom: 1px;
}

.hsTiltLeft .shape-fill {
  fill: var(--overall-bg-color);
}

/* section wave */
.hsWaveOpacity {
  position: relative;
  top: 0px;
  left: 0;
  width: 100%;
  /* overflow: hidden; */
  line-height: 0;
  transform: rotate(180deg);
  z-index: 2;
}

.hsWaveOpacity svg {
  position: relative;
  display: block;
  z-index: 2;
  transform: rotateY(0deg);
  bottom: 1px;
}

.hsWaveOpacity .shape-fill {
  fill: var(--overall-bg-color);
}

/* section wave invert */

.hsWaveInvert {
  position: absolute;
  top: 160px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  /* transform: rotate(180deg); */
}

.hsWaveInvert svg {
  position: relative;
  display: block;
  width: calc(144% + 1.3px);
  height: 50px;
  transform: rotateY(180deg);
}

.hsWaveInvert .shape-fill {
  fill: var(--overall-bg-color);
}

/* section opacity */

/* .hsWaveOpacity {
  position: absolute;
  top: 260px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.hsWaveOpacity svg {
  position: relative;
  display: block;
  width: calc(173% + 1.3px);
  height: 69px;
  transform: rotateY(180deg);
}

.hsWaveOpacity .shape-fill {
  fill: var(--header-bg-color);
} */

/* .gridWrapper:before {
  content: ' ';
  position: absolute;
  top: -7px;
  left: 2%;
  width: 96%;
  height: 50px;
  background-color: #ffffff;
  border: 2px solid #00000010;
  border-radius: 4px;
  opacity: 0.1;
}

.gridWrapper:after {
  content: ' ';
  position: absolute;
  top: -15px;
  left: 4%;
  width: 92%;
  height: 50px;
  background-color: #fff;
  border: 2px solid #00000010;
  border-radius: 4px;
  opacity: 0.05;
  z-index: -1;
}

.gridWrapper:before,
.gridWrapper:after {
  border: 2px solid #00000010;
} */

@keyframes modal-video {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }
  to {
    transform: translate(0, 0);
  }
}
.modal-video {
  outline: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.modal-video-effect-exit {
  opacity: 0;
}
.modal-video-effect-exit .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px);
}
.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table;
}
.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
}
.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
}
.modal-video-close-btn:before {
  transform: rotate(45deg);
}
.modal-video-close-btn:after {
  transform: rotate(-45deg);
}
.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}
