@import 'codemirror/lib/codemirror.css';
.CodeMirror {
  height: calc(100vh - 300px);
  font-size: 11px;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 5px;
  overflow: hidden;
}
body {
  -webkit-tap-highlight-color: transparent;
}

.dottedBG {
  /* background: linear-gradient(90deg, #eaeff2 13px, transparent 1%) center,
    linear-gradient(#eaeff2 13px, transparent 1%) center, #dee3e9;
  background-size: 15px 15px; */
  background-color: #edf1f5;
}

.appTopNav {
  background-color: #242327;
  color: #fff;
}

.appBG {
  /* background: linear-gradient(90deg, #f8fafc 13px, transparent 1%) center,
    linear-gradient(#fff 13px, transparent 1%) center, #fff;
  background-size: 15px 15px; */
  /* background: #fff; */

  /* background: linear-gradient(90deg, #eaeff2 13px, transparent 1%) center,
    linear-gradient(#eaeff2 13px, transparent 1%) center, #dee3e9;
  background-size: 15px 15px; */

  background-color: #edf1f5;
}

.collectionTopBar {
  /* background: #fafafa; */
}

.appTitleItem {
  font-family: Pensum;
}

.createOnepagerTopArea {
  height: 250px;
}

.templateViewBanner {
  min-height: 250px;
}

.sideNavWrapper {
  background-color: #212529;
  color: #fff;
}

.sideNavBorderColor {
  border-color: #6c757d50;
}

.collectionTab {
  margin-bottom: -2px;
  /* border-bottom: none; */
}

.miniGridPreview {
  height: 120px;
  width: 120px;
  /* border-left: 2px solid #000; */
  /* border-top: 2px solid #000; */
  border-radius: 5px;
  overflow: hidden;
}

.miniGridPreviewBlank {
  border: 2px solid #000;
}

.miniGridBox {
  /* box-shadow: inset -2px 0 0 #000, inset 0 -2px 0 #000,
    inset 0 0 0 4px rgb(243, 244, 246), inset -5px 0 0 rgb(243, 244, 246),
    inset 0 -5px 0 rgb(243, 244, 246); */
  box-shadow: inset 0 0 0 2px var(--canvas-inner-shadow);
  border-bottom: var(--canvas-thickness) solid var(--canvas-border);
  border-right: var(--canvas-thickness) solid var(--canvas-border);
}

.editCollectionModal {
  width: 550px;
}

.loginBox {
  width: 400px;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #ffc900;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 20px;
  background-color: #000;
  box-shadow: 0 0 10px #ffc900, 0 0 50px #ffc900;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -40px);
  -ms-transform: rotate(3deg) translate(0px, -40px);
  transform: rotate(3deg) translate(0px, -40px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 36px;
  height: 36px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #ffc900;
  border-left-color: #ffc900;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* RESPONSIVE CUSTOM */
/* @media screen and (display-mode: standalone) {
  header {
    height: 88px !important;
  }
}
header {
  padding-top: env(safe-area-inset-top);
} */
@media (max-device-width: 812px) {
  .gridWrapper {
    margin-top: -50px !important;
  }
  .heroInner {
    padding-bottom: 60px !important;
  }
  .boxes .box {
    border-radius: 0px !important;
    border: 0px !important;
    box-shadow: inset 0 0 0 3px var(--canvas-inner-shadow) !important;
    border-bottom: var(--canvas-thickness) solid var(--canvas-border) !important;
    /* overflow-y: auto; */
    overflow-x: auto;
    max-width: 100%;
  }
  .boxes .box:first-child {
    border-radius: 0px !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    border-top: var(--canvas-thickness) solid var(--canvas-border) !important;
  }
  .boxes .box:last-child {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }

  .boxBodyTextCallout {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .textCalloutLabel div {
    font-size: 18px !important;
  }

  .textCalloutHeadline {
    font-size: 36px !important;
    line-height: 1.2em !important;
  }

  .textCalloutSubHeadline {
    font-size: 18px !important;
    line-height: 1.2em !important;
  }

  .textCalloutButton button {
    font-size: 21px !important;
    line-height: 1.2em !important;
  }

  .boxTitleBlock {
    padding: 20px !important;
  }
  .boxBody {
    padding: 10px 20px !important;
    padding-bottom: 20px !important;
    padding-top: 0px !important;
  }
  .titleText {
    font-size: 27px !important;
  }
  .promptText {
    font-size: 14px !important;
  }
  .cardModal {
    width: 100% !important;
  }
  .boxTitleBlock:before {
    left: 0px !important;
    width: 4px !important;
    border-radius: 0px !important;
    height: calc(100% - 35px) !important;
    top: 14px !important;
    display: none !important;
  }
  .sectionWrapperBox .titleText {
    font-size: 22px !important;
  }
  html {
    background-color: var(--overall-bg-color) !important;
  }
  .sectionTable {
    min-width: max-content;
    overflow-x: scroll;
  }
  .sectionImage {
    position: absolute;
  }
  .heroTitle {
    font-size: 48px !important;
    line-height: 60px !important;
  }
  .heroBlurb {
    font-size: 18px !important;
    line-height: 28px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: auto !important;
  }

  .box.hasCustomBGColor {
    box-shadow: var(--canvas-block-shadow-custom-bg) !important;
  }

  .sectionTopLevelBGColor .box {
    box-shadow: var(--canvas-block-shadow-custom-bg) !important;
  }

  .boxBodyTextCallout {
    min-height: 250px;
  }
}

@media (min-device-width: 812px) and (max-device-width: 1366px) {
  .boxTitleBlock {
    padding: 30px !important;
  }
  .boxBody {
    padding: 10px 30px !important;
    padding-bottom: 20px !important;
    padding-top: 0px !important;
  }
}

.navTooltip {
  background-color: #212529;
}

.navTooltipBottom {
  background-color: #242327;
}

/* override baseweb */
div[data-baseweb='input'] {
  border: 1px solid #99999a;
  border-radius: 6px;
  /* box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.14) !important; */
  /* box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.05); */
  background-color: #fff;
  padding-right: 0px;
}

div[data-baseweb='input'] div:last-child {
  /* background-color: #fff; */
  /* background-color: #f3f4f6; */
  box-shadow: none;
  font-weight: 500;
}

div[data-baseweb='input']:focus-within {
  border: 1px solid rgb(59, 130, 246);
  box-shadow: 0 0 0 3px #6366f110, 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

div[data-baseweb='base-input'] {
  background-color: #fff;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05) !important;

  /* box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); */
}

.hasError div[data-baseweb='input'] {
  border: 1px solid #f64900;
  border-radius: 6px;
  /* background-color: #f64900; */
  padding-right: 0px;
}

.hasError div[data-baseweb='base-input'] {
  background-color: #f6490020;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05) !important;
}

.hasPositive div[data-baseweb='input'] {
  border: 1px solid #51c494;
  border-radius: 6px;
  background-color: #fff;
  padding-right: 0px;
}
.hasPositive div[data-baseweb='base-input'] {
  background-color: #51c49420;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05) !important;
}

button[data-baseweb='button'] {
  border-radius: 4px;
}

div[data-baseweb='popover'] {
  z-index: 999;
}

div[data-baseweb='modal'] {
  z-index: 999;
}

label[data-baseweb='form-control-label'] {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
}

.gridPreview .miniGridPreviewEmbed {
  height: 350px !important;
  width: 100%;
}

.iframeVersionPreview {
  width: 400%;
  height: 400%;
  transform: scale(0.25);
  transform-origin: 0 0;
}

.editRichTextQuill .ProseMirror {
  outline: none !important;
}

.editRichTextQuill .ProseMirror a {
  color: #2563eb;
  text-decoration: underline;
}

.ProseMirror {
  outline: none !important;
}

.simpleInputTest {
  border: 1px solid #000;
  border-radius: 4px;
  padding: 10px 15px;
  width: 100%;
}

.coverLeftSide {
  /* width: 800px; */
  /* padding-right: 100px; */
  /* padding-left: 100px; */
  display: flex;
  position: relative;
  /* width: 960px; */
  /* min-height: 100vh; */
  /* padding-top: 100px; */
  /* padding-right: 120px; */
  /* padding-bottom: 60px; */
  /* padding-left: 200px; */
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition-property: opacity, transform;
  z-index: 1;
  /* background-color: var(--overall-bg-color); */
}

.coverCoverImageWrapper {
  background-color: var(--overall-bg-color);
}

.coverRightSide .miniGridPreviewEmbed {
  height: 100%;
}

.coverRightSide .gridTitle {
  font-size: 34px;
  font-family: var(--header-title-font);
  padding: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 42px;
}

.embedPlaceholdersCover {
  padding-left: 32px;
  padding-right: 32px;
}

.miniGridPreviewEmbedCover {
  overflow: visible !important;
}

.miniGridBoxCover:hover {
  z-index: 999;
  border-radius: 4px;
  transform: scale(1.01);
  box-shadow: 0 0 13px 10px rgba(0, 0, 0, 0.16), 0 0 0 2px rgba(0, 0, 0, 1);
  border: none;
  /* border: var(--canvas-thickness) solid var(--canvas-border) !important; */
}

.coverSectionWrapper .boxTitleBlock {
  padding: 0px !important;
  padding-bottom: 30px !important;
}

.coverSectionWrapper .boxBody {
  padding: 0px !important;
  /* padding-bottom: 30px !important; */
}

.coverSectionWrapper .titleText {
  font-size: 46px;
}

.coverSectionWrapper .promptText {
  font-size: 21px;
  margin-top: 10px;
}

.coverSectionWrapper .boxTitleBlock:before {
  top: 0px;
  left: -20px;
  height: calc(100% - 25px);
}

.coverLowerProgressBar {
  bottom: -25px;
}

.coverRightSide .gridWrapper {
  margin-top: 0px !important;
  max-width: 100% !important;
}

.coverRightSide .box {
  border-radius: 0px !important;
}

.coverRightSide .boxes {
  min-height: calc(100vh - 69px) !important;
  width: 100% !important;
}

.coverRightSide.twoColumnHeightPadding .boxes {
  min-height: calc(100vh - 109px) !important;
}

.coverRightSide .hasTopBorder {
  border-top: none !important;
}

.coverRightSide .hasBottomBorder {
  border-bottom: none !important;
}

.coverRightSide .hasRightBorder {
  border-right: none !important;
  box-shadow: var(--canvas-block-shadow-no-right);
}

.coverRightSide .hasBottomBorder {
  border-bottom: none !important;
  box-shadow: var(--canvas-block-shadow-no-bottom);
}

.coverRightSide .hasBottomRightRadius {
  border-bottom: none !important;
  box-shadow: var(--canvas-block-shadow-no-bottom-right);
}

.twoColumnInnerShadow {
  box-shadow: inset -5px 0 7px rgba(0, 0, 0, 0.07);
}

.topBarTwoColumns {
  /* box-shadow: var(--canvas-block-shadow); */
  border-left: var(--canvas-thickness) solid var(--canvas-border);
  border-bottom: var(--canvas-thickness) solid var(--canvas-border);
  border-right: var(--canvas-thickness) solid var(--canvas-border);
}

.collectionTitleMargin {
  /* margin-top: 3px; */
}

.viewSingleOnepagerThumbnail {
  margin-bottom: -49px;
}

.singleOnepagerTabs {
  margin-bottom: -2px;
}

.singleOnepagerThumbnail {
  width: 500px;
}

.bottomImageBorder {
  bottom: 35px;
}

.twoColumnWrapper {
  min-height: calc(100vh - 69px) !important;
}

.twoColumnHeight {
  height: calc(100vh - 69px) !important;
}

.twoColumnHeightPadding {
  padding: 20px 20px;
  /* padding-right: 20px; */
  height: calc(100vh - 69px) !important;
}

/* two column no collection banner */
.twoColumnWrapperFull {
  height: 100vh !important;
  min-height: 100vh !important;
}
.twoColumnHeightFull {
  height: calc(100vh) !important;
}

.twoColumnHeightFull.coverRightSide .boxes {
  min-height: calc(100vh) !important;
  width: 100% !important;
}

.twoColumnHeightFull.coverRightSide.twoColumnHeightPadding .boxes {
  min-height: calc(100vh - 49px) !important;
}

/* -- */

@media (max-device-width: 812px) {
  .twoColumnWrapper {
    min-height: 100% !important;
  }

  .twoColumnHeight {
    height: 100% !important;
    padding-bottom: 20px;
  }
}

.bgOPNavTwoColumn {
  height: 69px;
  /* border-bottom: none !important; */
  border-bottom: var(--canvas-thickness) solid var(--canvas-border) !important;
  /* border-left: var(--canvas-thickness) solid var(--canvas-border) !important; */
  /* border-right: var(--canvas-thickness) solid var(--canvas-border) !important; */
}

.brandingAreaTwoColumn {
  height: 69px;
}

.twoColumnLayoutArrow:before {
  content: '';
  position: absolute;
  top: -9px;
  left: calc(50% - 9px);
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #242327;
}

.sectionImage {
  cursor: pointer;
}

.hover-effect-scale,
.hover-effect-moveup,
.hover-effect-scalelarge {
  transition: all 0.2s ease-in-out;
}

.hover-effect-scale:hover {
  transform: scale(1.02);
}

.hover-effect-scalelarge:hover {
  transform: scale(1.1);
}

.hover-effect-moveup:hover {
  transform: translate(0, -5px);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.181);
}

.hidden-important {
  display: none !important;
}

.headlessTableComponents tr:last-child {
  border-bottom: 0;
}

.headlessTableComponents td :last-child {
  border-right: 0;
}

/* kanban onepager types css */

.simpleKanban .react-kanban-column {
  border: 2px solid #ddd;
}

/* Sticky Page CSS */
.stickypagebutton {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
}

.sticky .stickypagebutton {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s, visibility 0.3s;
  transition-delay: 0.3s;
}

/* Sticky Page CSS */
.stickypagestart {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s, visibility 0.3s;
  transition-delay: 0.3s;
}

.sticky .stickypagestart {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
}

/* scale zoom */

.scaleOnHover:hover {
  transform: scale(1.01);
}

.scaleOnHover {
  transition: cubic-bezier(12, 0, 0, 1) 530ms all;
}

.isPrintPDF .bgOPNav,
.isPrintPDF .lowerBanner {
  display: none !important;
}

.isPrintPDF div.hero.relative.lg\:px-5.xl\:px-0 div.lg\:w-2\/5 {
  display: none !important;
}

.isPrintPDF
  div.hero.relative.lg\:px-5.xl\:px-0
  div.px-5.lg\:px-0.lg\:w-3\/5.lg\:pr-12 {
  width: 100% !important;
  text-align: center !important;
}

.isPrintPDF
  div.hero.relative.lg\:px-5.xl\:px-0
  div.px-5.lg\:px-0.lg\:w-3\/5.lg\:pr-12
  div,
.isPrintPDF
  div.hero.relative.lg\:px-5.xl\:px-0
  div.px-5.lg\:px-0.lg\:w-3\/5.lg\:pr-12
  h1 {
  display: block !important;
  text-align: center !important;
}

.isPrintPDF
  div.hero.relative.lg\:px-5.xl\:px-0
  > div
  > div
  > div.px-5.lg\:px-0.lg\:w-3\/5.lg\:pr-12
  > div.flex.flex-col.space-y-3.md\:space-y-0.md\:space-x-3.justify-center.items-center.md\:justify-start.md\:items-start.md\:flex-row.lg\:flex.md\:mb-3.lg\:mb-0.md\:justify-start.md\:items-start.md\:flex-row {
  display: none !important;
}

.isPrintPDF .gridWrapper {
  /* margin-top: -270px !important;
  margin-bottom: 50px !important; */
  padding: 5px;
}

.isPrintPDF .public-grid-wrapper {
  display: grid !important;
}

/* Audio Player Element */
.rhap_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1;
  font-family: inherit;
  width: 100%;
}
.rhap_container:focus:not(:focus-visible) {
  outline: 0;
}
.rhap_container svg {
  vertical-align: initial;
}

.rhap_header {
  margin-bottom: 10px;
}

.rhap_footer {
  margin-top: 5px;
}

.rhap_main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 8px;
}

.rhap_horizontal {
  flex-direction: row;
}
.rhap_horizontal .rhap_controls-section {
  margin-left: 8px;
}

.rhap_horizontal-reverse {
  flex-direction: row-reverse;
}
.rhap_horizontal-reverse .rhap_controls-section {
  margin-right: 8px;
}

.rhap_stacked-reverse {
  flex-direction: column-reverse;
}
.rhap_stacked-reverse .rhap_controls-section {
  margin-bottom: 8px;
}

.rhap_progress-section {
  display: flex;
  flex: 3 1 auto;
  align-items: center;
}

.rhap_progress-container {
  display: flex;
  align-items: center;
  height: 20px;
  flex: 1 0 auto;
  align-self: center;
  margin: 0 calc(10px + 1%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}
.rhap_progress-container:focus:not(:focus-visible) {
  outline: 0;
}

.rhap_time {
  color: #333;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
}

.rhap_progress-bar {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 5px;
  background-color: #dddddd;
  border-radius: 2px;
}

.rhap_progress-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: #222;
  border-radius: 2px;
}

.rhap_progress-bar-show-download {
  background-color: rgba(221, 221, 221, 0.5);
}

.rhap_download-progress {
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: #dddddd;
  border-radius: 2px;
}

.rhap_progress-indicator {
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  top: -8px;
  background: #222;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
}

.rhap_controls-section {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
}

.rhap_additional-controls {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
}

.rhap_repeat-button {
  font-size: 26px;
  width: 26px;
  height: 26px;
  color: #222;
  margin-right: 6px;
}

.rhap_main-controls {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rhap_main-controls-button {
  margin: 0 3px;
  color: #222;
  font-size: 35px;
  width: 35px;
  height: 35px;
}

.rhap_play-pause-button {
  font-size: 40px;
  width: 40px;
  height: 40px;
}

.rhap_volume-controls {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
}

.rhap_volume-button {
  flex: 0 0 26px;
  font-size: 26px;
  width: 26px;
  height: 26px;
  color: #222;
  margin-right: 6px;
}

.rhap_volume-container {
  display: flex;
  align-items: center;
  flex: 0 1 100px;
  user-select: none;
  -webkit-user-select: none;
}

.rhap_volume-bar-area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 14px;
  cursor: pointer;
}
.rhap_volume-bar-area:focus:not(:focus-visible) {
  outline: 0;
}

.rhap_volume-bar {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 4px;
  background: #dddddd;
  border-radius: 2px;
}

.rhap_volume-indicator {
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  left: 0;
  top: -4px;
  background: #222;
  opacity: 0.9;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;
  cursor: pointer;
}
.rhap_volume-indicator:hover {
  opacity: 0.9;
}

.rhap_volume-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: #222;
  border-radius: 2px;
}

/* Utils */
.rhap_button-clear {
  background-color: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}
.rhap_button-clear:hover {
  opacity: 0.9;
  transition-duration: 0.2s;
}
.rhap_button-clear:active {
  opacity: 0.95;
}
.rhap_button-clear:focus:not(:focus-visible) {
  outline: 0;
}
